import React from 'react';
import { Link, graphql } from 'gatsby';
import ReactPlayer from 'react-player';
import ScrollAnimation from 'react-animate-on-scroll';
import Slider from "react-slick";
import Layout from '../components/layout'
import SEO from "../components/seo";
import "animate.css/animate.compat.css";
import Header from '../components/header';
import Footer from '../components/footer';
import Contact from '../components/contact';
import Map from '../images/worldmap.svg';

class IndexPage extends React.Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      fade: true,
      speed: 3500,
      pauseOnHover: false,
    };
    return (
      <Layout>
        <SEO title="主頁" />
        <Contact />
        <Header />
        <div className="slider-banner">   
        <Slider {...settings}>
          <div className="banner">
            <div className="banner-second-main">
              <div className="container in-banner w-container">
                <div className="flex-row make-verical w-row">
                  <div className="w-col w-col-6 w-col-stack">
                  </div>
                  <div className="w-clearfix w-col w-col-6 w-col-stack">
                    <div className="hero-text-box">
                      <h3 style={{ color:"white" }}>K Laser Technology</h3>
                      <h1 className="main-banner-title">光群<br />雷射</h1>
                      <div className="top-margin medium">
                        <Link to="/products/" className="link-wrapper big-link w-inline-block">
                          <div className="link-text">深入探索</div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>    
          </div>
          <div className="banner2">
            <div className="banner-second-main">
              <div className="container in-banner w-container">
                <div className="flex-row make-verical w-row">
                  <div className="w-col w-col-6 w-col-stack">
                  </div>
                  <div className="w-clearfix w-col w-col-6 w-col-stack">
                    <div className="hero-text-box">
                      <h3 style={{ color:"white" }}>K Laser Technology</h3>
                      <h1 className="main-banner-title">光群<br />雷射</h1>
                      <div className="top-margin medium">
                        <Link to="/products/" className="link-wrapper big-link w-inline-block">
                          <div className="link-text">深入探索</div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>
          <div className="banner3">
            <div className="banner-second-main">
              <div className="container in-banner w-container">
                <div className="flex-row make-verical w-row">
                  <div className="w-col w-col-6 w-col-stack">
                  </div>
                  <div className="w-clearfix w-col w-col-6 w-col-stack">
                    <div className="hero-text-box">
                      <h3 style={{ color:"white" }}>K Laser Technology</h3>
                      <h1 className="main-banner-title">光群<br />雷射</h1>
                      <div className="top-margin medium">
                        <Link to="/products/" className="link-wrapper big-link w-inline-block">
                          <div className="link-text">深入探索</div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>
        </Slider>
        </div>
        <div className="banner3 mobile-banner">
          <div className="banner-second-main">
            <div className="container in-banner w-container">
              <div className="flex-row make-verical w-row">
                <div className="w-col w-col-6 w-col-stack">
                </div>
                <div className="w-clearfix w-col w-col-6 w-col-stack">
                  <div className="hero-text-box">
                    <h3 style={{ color:"white" }}>K Laser Technology</h3>
                      <h1 className="main-banner-title">光群<br />雷射</h1>
                    <div className="top-margin medium">
                      <Link to="/products/" className="link-wrapper big-link w-inline-block">
                        <div className="link-text">深入探索</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 
        </div>
        
        <section id="Scroll" className=" page-content section more-padding">
          <div className="container w-container">
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
              <h2>光群雷射科技</h2>
              <h3 style={{ paddingBottom:"1em" }}>光群雷射科技股份有限公司自1988年成立以來致力投入雷射全息影像專業領域，為全球技術領先全像材料供應商。</h3>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
              <Link to="/about/" className="link-wrapper big-link w-inline-block">
                <div className="link-text">了解更多</div>
              </Link>
            </ScrollAnimation>
          </div>
        </section>

        <section className="section page-content more-padding" style={{ background:"#1c1c1f"}}>
          <div className="container w-container">
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
              <h2 className="invert-text-color">引領世界進入無版縫時代</h2>
              <h3 style={{ paddingBottom:"1em" }}>光群雷射獨家開發，業界唯一的TSL無版縫技術，協助客戶提升產能與生產效率。</h3>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
              <Link to="/truseamless/" className="link-wrapper big-link w-inline-block">
                <div className="link-text">了解更多</div>
              </Link>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
              <div className="video" style={{ marginTop:"100px" }}>
                <ReactPlayer
                  url="/static/videos/F019_Double_Lens_compressed_hojdyy.mp4"
                  playing
                  loop
                  muted
                  width="480px"
                  height="480px"
                  style={{ margin: "0 auto" }}
                  playbackRate={2}
                />          
              </div>
            </ScrollAnimation>
          </div>
        </section>

        <section className="section page-content">
          <div className="container w-container">
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
              <div className="w-row">
                <div className="w-col w-col-7 w-col-stack">
                  <img style={{ marginTop:"2em" }} src={Map} alt="map" />
                </div>
                <div className="w-col w-col-5 w-col-stack" style={{ padding:"50", marginTop:"3em" }}>
                  <h3 style={{ color:"#232323" }}>光群集團全球據點</h3>
                  <p>光群集團在全球擁有多個生產據點與銷售中心，並且，我們積極與各地區知名經銷商密切合作，提供專業、即時的客戶服務，滿意度享譽國際。</p>
                  <Link to="/about#contact" className="link-wrapper big-link w-inline-block">
                    <div className="link-text">了解更多</div>
                  </Link>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </section>
        <section className="mobile-contact-form">
          <form action="https://formspree.io/xqkzwzgb" method="POST">
            <div className="w-row">
              <div className="w-col w-col-6">
                <input className="text-field w-input" placeholder='姓' type='text' name='last' />
              </div>
              <div className="w-col w-col-6">
                <input className="text-field w-input" placeholder='名' type='text' name='first' />
              </div>
            </div>
            <div className="w-row">
              <div className="w-col w-col-6">
                <input className="text-field w-input" placeholder='電子郵件' type='email' name='email' />
              </div>
              <div className="w-col w-col-6">
                <input className="text-field w-input" placeholder='電話號碼' type='text' name='phone' />
              </div>
            </div>
            <div className="w-row">
              <div className="w-col w-col-6">
                <input className="text-field w-input" placeholder='公司' type='text' name='company' />
              </div>
              <div className="w-col w-col-6">
                <input className="text-field w-input" placeholder='職稱' type='text' name='position' />
              </div>
            </div>
            <div className="w-row">
              <div className="w-col w-col-6">
                <input className="text-field w-input" placeholder='國家' type='text' name='country' />
              </div>
              <div className="w-col w-col-6">
                <textarea className="text-field area w-input"label='訊息' name="message" placeholder='歡迎提出任何疑問...' />
              </div>
            </div>
            <div className="w-col w-col-6" style={{ textAlign: "center" }}>
              <button className="button" type="submit">送出</button>
            </div>          
          </form>
        </section>

        <Footer />
      </Layout>
    )
  }
}

export default IndexPage;

export const pageQuery = graphql`  
  query IndexQuery {
    allStrapiProduct {
      edges {
        node {
          id
          language
          description
        }
      }
    }
  }
`
